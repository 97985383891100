@media screen and (min-width: 1366px) {
    .NavItems {
        flex-direction: row;
    }

    .StickyNavContainer{
        position: fixed;
        width: 100vw;
        max-width: 100vw;
        padding-left: 6.94vw;
        padding-right: 6.94vw;
        padding-top: 3.9vw;
        overflow: hidden;
        z-index: 999;
        pointer-events: none;
    }

    .NavBar-Item{
        text-align: right;
        pointer-events: all;
        cursor: pointer;
        width: 7vw;
        font-family: Manrope;
        font-style: normal;
        font-weight: 500;
        font-size: 1.11vw;
        line-height: 2vw;
        letter-spacing: 0.05em;
        color: #000000;
        transition: 0.2s;
        opacity: 1;
    }

    .NavBar-Item:hover,
    .NavBar-Item:active{
        text-decoration: none;
        color: #000000;
        opacity: 0.5;
    }

    .NavBar-Item-Active,
    .NavBar-Item-Hover{
        padding-left:0 !important;
        margin-left: 0 !important;
        display: list-item;
        list-style-type: disc;
        list-style-position: inside;
        text-decoration: none;
        color: #000000;
        transition: none;
        opacity: 1;
    }

    .OrigamiServicesLogo{
        cursor: pointer;
        pointer-events: all;
        float: left;
        fill: #000000;
        width: 10vw;
    }
}

@media screen and (max-width: 1365px) {

    .StickyNavContainer{
        position: fixed;
        min-width: 100vw;
        max-width: 100vw;
        padding-left: 3.94vw;
        padding-right: 3.94vw;
        padding-top: 3.9vw;
        z-index: 999;
        overflow: hidden;
        pointer-events: none;
    }
}

@media screen and (min-width: 768px) and (max-width: 1365px) {
    .NavItems {
        flex-direction: row;
    }

    .NavBar-Item{
        text-align: right;
        pointer-events: all;
        width: 15vw;
        font-family: Manrope;
        font-style: normal;
        font-weight: 500;
        font-size: 1.82vw;
        line-height: 3vw;
        letter-spacing: 0.05em;
        color: #000000 !important;
        transition: 500ms;
        opacity: 1;
    }

    .NavBar-Item:hover,
    .NavBar-Item:active{
        text-decoration: none;
        color: #000000 !important;
        opacity: 0.7;
    }

    .NavBar-Item-Active,
    .NavBar-Item-Hover{
        padding-left:0 !important;
        margin-left: 0 !important;
        display: list-item;
        list-style-type: disc;
        list-style-position: inside;
        text-decoration: none;
        color: #000000;
        transition: none;
        opacity: 1;
    }

    .OrigamiServicesLogo{
        cursor: pointer;
        pointer-events: all;
        float: left;
        fill: #000000;
        width: 15vw;
    }
}


@media screen and (max-width: 767px) {
    .NavItems {
        flex-direction: column;
    }

    .NavBar-Item{
        text-align: right;
        pointer-events: all;
        width: 30vw;
        margin-top: 1.5vw;
        margin-bottom: 1.5vw;
        cursor: pointer;
        font-family: Manrope;
        font-style: normal;
        font-weight: 500;
        font-size: 3.5vw;
        letter-spacing: 0.1em;
        color: #000000;
        transition: 500ms;
        opacity: 1;
    }

    .NavBar-Item:hover,
    .NavBar-Item:active{
        text-decoration: none;
        opacity: 0.7;
    }

    .NavBar-Item-Active,
    .NavBar-Item-Hover{
        padding-left:0 !important;
        margin-left: 0 !important;
        display: list-item;
        list-style-type: disc;
        list-style-position: inside;
        text-decoration: none;
        color: #000000;
        transition: none;
        opacity: 1;
    }

    .OrigamiServicesLogo{
        cursor: pointer;
        pointer-events: all;
        float: left;
        fill: #000000;
        width: 30vw;
    }
}