
*{
    scrollbar-width: none;
}


::-webkit-scrollbar {
    display: none;
}

.WebPage {
    margin: 0 !important;
    padding: 0 !important;
    position: absolute;
    top: 0;
    left: 0; 
    right: 0;
    bottom: 0;
    background-color: #0B0D17;
    width: 100%;
}

html,
body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    scrollbar-width: none !important;
    background-color: #FFFFFF;
}

a,
a:hover {
    color: #000000;
}

@media screen and (min-width: 1366px) {

    .Footer {
        z-index: 999;
        text-align: center;
        height: auto;
        font-family: Manrope;
        position: relative;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: #0B0D17;
        padding-top: 80px;
        padding-bottom: 80px
    }

    .OrigamiServicesFooterLogo {
        cursor: pointer;
        fill: #FFFFFF;
        width: 10vw;
        margin-bottom: 2.5%;
    }

    .FooterText {
        cursor: pointer;
        font-family: Manrope;
        color: #FFFFFF;
        font-style: normal;
        font-weight: 400;
        font-size: 1.11vw;
        line-height: 300%;
        letter-spacing: 0.1em;
        margin-top: 7px;
        transition: 0.2s
    }

    .FooterText:hover {
        color: #A2A2A2;
        text-decoration: none;
    }

    .FooterNav {
        width: 100%;
        justify-content: center;
    }

    .FooterBranding {
        font-family: Manrope;
        color: #D9DBE1;
        font-style: normal;
        font-weight: 500;
        font-size: 0.97vw;
        line-height: 300%;
        letter-spacing: 0.1em;
        margin-top: 7px;
        transition: 0.2s
    }
}

@media screen and (min-width: 768px) and (max-width: 1365px) {

    .Footer {
        z-index: 999;
        text-align: center;
        height: auto;
        font-family: Manrope;
        position: relative;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: #0B0D17;
        padding-top: 80px;
        padding-bottom: 80px
    }

    .OrigamiServicesFooterLogo {
        cursor: pointer;
        fill: #FFFFFF;
        width: 15vw;
        margin-bottom: 2.5%;
    }

    .FooterText {
        cursor: pointer;
        font-family: Manrope;
        color: #FFFFFF;
        font-style: normal;
        font-weight: 500;
        font-size: 2.08vw;
        line-height: 150%;
        letter-spacing: 0.1em;
        margin-top: 7px;
        transition: 0.2s
    }

    .FooterNav {
        width: 100%;
        justify-content: center;
    }

    .FooterText:hover {
        color: #A2A2A2;
        text-decoration: none;
    }

    .FooterBranding {
        font-family: Manrope;
        color: #D9DBE1;
        font-style: normal;
        font-weight: 500;
        font-size: 1.82vw;
        line-height: 11px;
        letter-spacing: 0.1em;
        margin-top: 7px;
    }
}


@media screen and (max-width: 767px) {

    .Footer {
        z-index: 999;
        height: auto;
        font-family: Manrope;
        position: relative;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: #0B0D17;
        padding-top: 10vw;
        padding-bottom: 10vw;
    }

    .OrigamiServicesFooterLogo {
        cursor: pointer;
        margin-left: 5vw;
        fill: #FFFFFF;
        width: 30vw;
        margin-bottom: 2.5%;
    }

    .FooterText {
        cursor: pointer;
        font-family: Manrope;
        color: #FFFFFF;
        font-style: normal;
        font-weight: 500;
        font-size: 5vw;
        line-height: 300%;
        letter-spacing: 0.1em;
        margin-top: 7px;
        transition: 0.2s
    }

    .FooterNav {
        flex-direction: row;
        padding-left: 5vw;
        width: 100vw;
        justify-content: left;
    }

    .FooterText:hover {
        color: #A2A2A2;
        text-decoration: none;
    }

    .FooterBranding {
        margin-left: 5vw;
        font-family: Manrope;
        color: #D9DBE1;
        font-style: normal;
        font-weight: 500;
        font-size: 3.43vw;
        line-height: 11px;
        letter-spacing: 0.1em;
        margin-top: 7px;
    }
}