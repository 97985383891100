.HomeDesktopContent {
    min-width: 100vw;
    background-color: #FFFFFF;
    padding: 0;
    margin: 0;
    overflow: hidden;
    min-height: 768px !important;
}

.HomeTabletContent {
    height: 100%;
    max-height: 768px;
    width: 100vw;
    max-width: 100vw;
    background-color: #FFFFFF;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.HomeMobileContent {
    padding-top: 5vw !important;
    padding-bottom: 7vw !important;
    height: 100%;
    max-height: 667px;
    width: 100vw;
    max-width: 100vw;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.HomeTabletContentStretch {
    width: 100vw;
    max-width: 100vw;
    background-color: #FFFFFF;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.HomeMobileContentStretch {
    padding-bottom: 7vw !important;
    width: 100vw;
    max-width: 100vw;
    background-color: #FFFFFF;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.HomeEmail,
.HomeEmail:hover,
.HomeEmail:active {
    color: #18191F;
}

@media screen and (min-width: 1366px) {

    .DesktopBackground {
        position: absolute;
        height: 100%;
        width: 100vw;
        padding: 0;
        margin: 0;
        overflow: hidden;
        z-index: 0;
    }

    .DesktopImageContainer{
        height: 100%;
        width: 100vw;
        align-items: center;
        justify-content: center;
        align-content: center;
    }

    .HomeHeading {
        padding-bottom: 1vh;
        font-family: Manrope;
        font-size: 5vw;
        font-style: normal;
        text-align: left;
        color: #18191F;
        font-weight: 800;
        line-height: 140%;
        font-feature-settings: 'liga' off;
        
        letter-spacing: 0.025em;
    }

    .HomeTimeline {
        padding-bottom: 1vh;
        font-family: Manrope;
        font-size: 1.51vw;
        font-style: normal;
        text-align: left;
        color: #18191F;
        font-weight: 500;
        line-height: 140%;
        
        letter-spacing: 0.025em;
    }

    .HomeCTA {
        padding-left: 6.94vw;
        min-height: 768px !important;
        height: 100vh;
        align-items: center;
        text-align: left;

        background: linear-gradient(194.01deg, #D9E8EF 9.6%, #EAF2F5 89.64%);
    }

    .HomeDetails {
        height: 80vh;
        align-items: center;
        justify-content: center;
        align-content: center;
    }

    .HomeImage{
        margin-right: 5vw;
        float: right;
        height: 100%;
        width: 25vw;
        object-fit: cover;
    }

    .HomeSideImage{
        position: absolute;
        min-height: 768px;
        height: 100vh;
        width: 100vw;
        object-fit: cover;
        z-index: 0;
    }

    .SlideshowImage{
        height: 100vh;
        width: 100vw;
        object-fit: cover;
        opacity: 0.7;
    }

    .HomeSubtitle {
        font-family: Manrope;
        font-size: 1.71vw;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        letter-spacing: 0.05em;
        text-align: left;
        color: #18191F;
        z-index: 5;
    }

    .ApproachSection {
        padding: 6.94vw;
        padding-top: 0;
    }

    .HomeInformationIcon {
        text-align: center;
        font-size: 4vw;
        margin: 2vw;
    }

    .HomeInformationIcon2 {
        text-align: center;
        font-size: 3vw;
    }

    .HomeInformationHeading {
        margin-top: 6.94vw;
        text-align: center;
        margin-bottom: 0.5em;
        font-family: Manrope;
        font-size: 3.33vw;
        font-style: normal;
        font-weight: 800;
        line-height: 150%;
        color: #18191F;
    }

    .HomeInformationHeading2 {
        margin-top: 6.94vw;
        text-align: left;
        margin-bottom: 0.5em;
        font-family: Manrope;
        font-size: 3.33vw;
        font-style: normal;
        font-weight: 800;
        line-height: 150%;
        color: #18191F;
    }

    .HomeInformation {
        text-align: center;
        margin-bottom: 1em;
        font-family: Manrope;
        font-size: 1.25vw;
        font-style: normal;
        font-weight: bold;
        line-height: 150%;
        letter-spacing: 0.05em;
        color: #18191F;
    }

    .HomeInformationText {
        text-align: center;
        margin-left: 3vw;
        margin-right: 3vw;
        margin-bottom: 1em;
        font-family: Manrope;
        font-size: 1.11vw;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        letter-spacing: 0.05em;
        color: #18191F;
    }

    .ServicesSection {
        padding: 6.94vw;
        padding-top: 0;
    }

    .ServicesHeading {
        margin-bottom: 1vh;
        text-align: left;
        font-family: Manrope;
        font-size: 1.66vw;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: 0.05em;
        color: #18191F;
    }

    .ServicesText {
        text-align: left;
        margin-bottom: 3vh;
        font-family: Manrope;
        font-size: 1.25vw;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        letter-spacing: 0.05em;
        color: #18191F;
    }

    .BenefitsPoint {
        margin-top: 2.5vw;
        height: 10vw;
    }
    
    .BenefitsSection {
        padding: 6.94vw;
        padding-top: 0;
    }

    .BenefitsHeading {
        margin-bottom: 1vh;
        text-align: left;
        font-family: Manrope;
        font-size: 1.66vw;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: 0.05em;
        color: #18191F;
    }

    .BenefitsText {
        text-align: left;
        margin-bottom: 3vh;
        margin-right: 3vh;
        font-family: Manrope;
        font-size: 1.25vw;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        letter-spacing: 0.05em;
        color: #18191F;
    }

    .Carousel {
        width: 100vw;
    }

    .GalleryImage {
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 0;
        height: 80vh;
        object-position: center;
        object-fit: cover;
        background-color: #FFFFFF;
    }
}

@media screen and (min-width: 768px) and (max-width: 1365px) {

    .TabletBackground {
        position: absolute !important;
        height: 100%;
        max-height: 768px;
        width: 100vw;
        padding: 0;
        margin: 0;
        overflow: hidden;
        z-index: 0;
    }

    .TabletImageContainer{
        height: 100%;
        max-height: 768px;
        width: 100vw;
    }

    .HomeHeading {
        padding-bottom: 1vh;
        font-family: Manrope;
        font-size: 9.37vw;
        font-style: normal;
        text-align: left;
        color: #18191F;
        font-weight: 800;
        line-height: 140%;
        font-feature-settings: 'liga' off;
        
        letter-spacing: 0.025em;
    }

    .HomeTimeline {
        padding-bottom: 1vh;
        font-family: Manrope;
        font-size: 2.14vw;
        font-style: normal;
        text-align: left;
        color: #18191F;
        font-weight: 500;
        line-height: 140%;
        
        letter-spacing: 0.025em;
    }

    .HomeCTA {
        padding-left: 3.94vw;
        padding-right: 3.94vw;
        max-height: 768px !important;
        height: 100vh;
        align-items: center;
        text-align: left;
    }

    .HomeDetails {
        margin-top: 10%;
        align-items: center;
        justify-content: center;
        align-content: center;
    }

    .HomeDetails2 {
        align-items: center;
        justify-content: center;
        align-content: center;
    }

    .HomeImage{
        margin-right: 5vw;
        float: right;
        height: 100%;
        width: 40vw;
        object-fit: cover;
    }

    .HomeSideImage{
        position: absolute;
        max-height: 768px;
        height: 100vh;
        width: 100vw;
        object-fit: cover;
        object-position: 70%;
    }

    .SlideshowImage{
        position: absolute;
        height: 100vh;
        max-height: 768px;
        width: 100vw;
        object-fit: cover;
        opacity: 0.7;
    }

    .HomeSubtitle {
        font-family: Manrope;
        font-size: 2.34vw;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        letter-spacing: 0.05em;
        text-align: left;
        color: #18191F;
        z-index: 5;
    }

    .ApproachSection {
        padding: 3.94vw;
        padding-top: 0;
    }

    .HomeInformationIcon {
        text-align: center;
        font-size: 5vw;
        margin: 2vw;
    }

    .HomeInformationIcon2 {
        text-align: center;
        font-size: 4vw;
    }

    .HomeInformationHeading {
        margin-top: 6.94vw;
        text-align: center;
        margin-bottom: 0.5em;
        font-family: Manrope;
        font-size: 6.25vw;
        font-style: normal;
        font-weight: 800;
        line-height: 150%;
        color: #18191F;
    }

    .HomeInformationHeading2 {
        margin-top: 6.94vw;
        text-align: left;
        margin-bottom: 0.5em;
        font-family: Manrope;
        font-size: 6.25vw;
        font-style: normal;
        font-weight: 800;
        line-height: 150%;
        color: #18191F;
    }

    .HomeInformation {
        text-align: center;
        margin-bottom: 1em;
        font-family: Manrope;
        font-size: 2.34vw;
        font-style: normal;
        font-weight: bold;
        line-height: 150%;
        letter-spacing: 0.05em;
        color: #18191F;
    }

    .HomeInformationText {
        text-align: center;
        margin-bottom: 1em;
        font-family: Manrope;
        font-size: 2.08vw;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        letter-spacing: 0.05em;
        color: #18191F;
    }

    .ServicesSection {
        padding: 3.94vw;
    }

    .ServicesHeading {
        margin-bottom: 1vh;
        text-align: left;
        font-family: Manrope;
        font-size: 3.125vw;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: 0.05em;
        color: #18191F;
    }

    .ServicesText {
        text-align: left;
        margin-bottom: 3vh;
        font-family: Manrope;
        font-size: 2.34vw;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        letter-spacing: 0.05em;
        color: #18191F;
    }
    
    .BenefitsSection {
        padding: 3.94vw;
        padding-top: 0;
    }

    .BenefitsPoint {
        height: 30vw;
    }

    .BenefitsHeading {
        margin-bottom: 1vh;
        text-align: left;
        font-family: Manrope;
        font-size: 3.125vw;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: 0.05em;
        color: #18191F;
    }

    .BenefitsText {
        text-align: left;
        margin-right: 3vh;
        margin-bottom: 3vh;
        font-family: Manrope;
        font-size: 2.34vw;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        letter-spacing: 0.05em;
        color: #18191F;
    }

    .Carousel {
        width: 100vw;
    }

    .GalleryImage {
        width: 100vw;
        object-position: center;
        object-fit: cover;
    }
}

@media screen and (max-width: 767px) {

    .HomeMobileBackground {
        position: absolute;
        height: 80vh;
        width: 100vw;
        padding: 0;
        margin: 0;
        overflow: hidden;
    }

    .HomeMobileImageContainer{
        height: 80vh;
        width: 100vw;
    }

    .HomeSideImage{
        position: absolute;
        height: 80vh;
        max-height: 80vh;
        width: 100vw;
        object-fit: cover;
        object-position: 80%;
    }

    .HomeMobileBackground2 {
        position: relative !important;
        height: 80%;
        max-height: 667px;
        width: 100vw;
        padding: 0;
        margin: 0;
        overflow: hidden;
        z-index: 0;
    }

    .HomeMobileImageContainer2{
        max-height: 667px;
        width: 100vw;
        align-items: center;
        justify-content: center;
        align-content: center;
    }

    .HomeHeading {
        padding-bottom: 1vh;
        font-family: Manrope;
        font-size: 12.5vw;
        font-style: normal;
        color: #18191F;
        font-weight: 800;
        line-height: 140%;
    }

    .HomeTimeline {
        padding-bottom: 1vh;
        font-family: Manrope;
        font-size: 4.5vw;
        font-style: normal;
        text-align: left;
        color: #18191F;
        font-weight: 500;
        line-height: 140%;
        
        letter-spacing: 0.025em;
    }

    .MobileHome {
        height: 100%;
        align-items: center;
        justify-content: center;
        align-content: center;
    }

    .HomeCTA {
        padding-left: 3.94vw;
        padding-right: 3.94vw;
        height: 80vh;
        align-items: center;
        text-align: center;

        background: linear-gradient(194.01deg, #D9E8EF 9.6%, #EAF2F5 89.64%);
    }

    .HomeDetails {
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 20%;
    }

    .HomeDetails2 {
        margin-left: 5%;
        margin-right: 5%;
        margin-top: calc(10% + 2em);
        margin-bottom: 10%;
        align-items: center;
        justify-content: center;
        align-content: center;
    }

    .HomeImage{
        height: 60vh;
        width: 100vw;
        object-fit: cover;
    }

    .SlideshowImage{
        position: absolute;
        height: 100vh;
        max-height: 667px;
        width: 100vw;
        object-fit: cover;
        opacity: 0.7;
    }

    .HomeSubtitle {
        font-family: Manrope;
        font-size: 4.375vw;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        letter-spacing: 0.05em;
        color: #18191F;
    }

    .ApproachSection {
        padding: 3.94vw;
        padding-top: 5vw;
        padding-bottom: 5vw;
    }

    .HomeInformationIcon {
        text-align: center;
        font-size: 6.5vw;
        margin: 2vw;
    }

    .HomeInformationIcon2 {
        margin-top: 1.5vh;
        text-align: center;
        font-size: 5vw;
    }

    .HomeInformationHeading {
        margin-top: 6.94vw;
        text-align: center;
        margin-bottom: 0.5em;
        font-family: Manrope;
        font-size: 9vw;
        font-style: normal;
        font-weight: 800;
        line-height: 150%;
        color: #18191F;
    }

    .HomeInformation {
        text-align: center;
        margin: 1em;
        font-family: Manrope;
        font-size: 4.625vw;
        font-style: normal;
        font-weight: bold;
        line-height: 150%;
        letter-spacing: 0.05em;
        color: #18191F;
    }

    .HomeInformationText {
        text-align: center;
        margin: 1em;
        margin-top: 0;
        font-family: Manrope;
        font-size: 4vw;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        letter-spacing: 0.05em;
        color: #18191F;
    }

    .ServicesSection {
        padding: 3.94vw;
        padding-top: 5vw;
        padding-bottom: 5vw;
    }

    .ServicesHeading {
        margin-bottom: 1vh;
        text-align: left;
        font-family: Manrope;
        font-size: 6.5vw;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: 0.05em;
        color: #18191F;
    }

    .ServicesText {
        text-align: left;
        margin-bottom: 3vh;
        font-family: Manrope;
        font-size: 4.625vw;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        letter-spacing: 0.05em;
        color: #18191F;
    }

    .BenefitsSection {
        padding: 3.94vw;
        padding-top: 5vw;
        padding-bottom: 5vw;
    }

    .BenefitsHeading {
        margin-bottom: 1vh;
        text-align: left;
        font-family: Manrope;
        font-size: 6.5vw;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: 0.05em;
        color: #18191F;
    }

    .BenefitsText {
        text-align: left;
        margin-bottom: 3vh;
        font-family: Manrope;
        font-size: 4.625vw;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        letter-spacing: 0.05em;
        color: #18191F;
    }

    .Carousel {
        width: 100vw;
        height: 30vh;
    }

    .GalleryImage {
        width: 100vw;
        height: 30vh;
        object-position: center;
        object-fit: cover;
    }

    .ContentSVG {
        transform: scale(2.5);
        float: center;
    }

    .ContentSVG2 {
        transform: scale(2.5);
        float: center;
    }
}